 /*
        Animation is here since we can't do this in JS with inline styles :(
      */
      @keyframes skeletonAnimation {
        0% {
          opacity: 0.8;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 0.8;
        }
      }

      /*
        Reset all styles of an element
      */
     .reset-this {
        animation : none;
        animation-delay : 0;
        animation-direction : normal;
        animation-duration : 0;
        animation-fill-mode : none;
        animation-iteration-count : 1;
        animation-name : none;
        animation-play-state : running;
        animation-timing-function : ease;
        backface-visibility : visible;
        background : 0;
        background-attachment : scroll;
        background-clip : border-box;
        background-color : transparent;
        background-image : none;
        background-origin : padding-box;
        background-position : 0 0;
        background-position-x : 0;
        background-position-y : 0;
        background-repeat : repeat;
        background-size : auto auto;
        border : 0;
        border-style : none;
        border-width : medium;
        border-color : inherit;
        border-bottom : 0;
        border-bottom-color : inherit;
        border-bottom-left-radius : 0;
        border-bottom-right-radius : 0;
        border-bottom-style : none;
        border-bottom-width : medium;
        border-collapse : separate;
        border-image : none;
        border-left : 0;
        border-left-color : inherit;
        border-left-style : none;
        border-left-width : medium;
        border-radius : 0;
        border-right : 0;
        border-right-color : inherit;
        border-right-style : none;
        border-right-width : medium;
        border-spacing : 0;
        border-top : 0;
        border-top-color : inherit;
        border-top-left-radius : 0;
        border-top-right-radius : 0;
        border-top-style : none;
        border-top-width : medium;
        bottom : auto;
        box-shadow : none;
        box-sizing : content-box;
        caption-side : top;
        clear : none;
        clip : auto;
        color : inherit;
        columns : auto;
        column-count : auto;
        column-fill : balance;
        column-gap : normal;
        column-rule : medium none currentColor;
        column-rule-color : currentColor;
        column-rule-style : none;
        column-rule-width : none;
        column-span : 1;
        column-width : auto;
        content : normal;
        counter-increment : none;
        counter-reset : none;
        cursor : auto;
        direction : ltr;
        display : inline;
        empty-cells : show;
        float : none;
        font : normal;
        font-family : inherit;
        font-size : medium;
        font-style : normal;
        font-variant : normal;
        font-weight : normal;
        height : auto;
        hyphens : none;
        left : auto;
        letter-spacing : normal;
        line-height : normal;
        list-style : none;
        list-style-image : none;
        list-style-position : outside;
        list-style-type : disc;
        margin : 0;
        margin-bottom : 0;
        margin-left : 0;
        margin-right : 0;
        margin-top : 0;
        max-height : none;
        max-width : none;
        min-height : 0;
        min-width : 0;
        opacity : 1;
        orphans : 0;
        outline : 0;
        outline-color : invert;
        outline-style : none;
        outline-width : medium;
        overflow : visible;
        overflow-x : visible;
        overflow-y : visible;
        padding : 0;
        padding-bottom : 0;
        padding-left : 0;
        padding-right : 0;
        padding-top : 0;
        page-break-after : auto;
        page-break-before : auto;
        page-break-inside : auto;
        perspective : none;
        perspective-origin : 50% 50%;
        position : static;
        /* May need to alter quotes for different locales (e.g fr) */
        quotes : '\201C' '\201D' '\2018' '\2019';
        right : auto;
        tab-size : 8;
        table-layout : auto;
        text-align : inherit;
        text-align-last : auto;
        text-decoration : none;
        text-decoration-color : inherit;
        text-decoration-line : none;
        text-decoration-style : solid;
        text-indent : 0;
        text-shadow : none;
        text-transform : none;
        top : auto;
        transform : none;
        transform-style : flat;
        transition : none;
        transition-delay : 0s;
        transition-duration : 0s;
        transition-property : none;
        transition-timing-function : ease;
        unicode-bidi : normal;
        vertical-align : baseline;
        visibility : visible;
        white-space : normal;
        widows : 0;
        width : auto;
        word-spacing : normal;
        z-index : auto;
        /* basic modern patch */
        all: initial;
        all: unset;
    }



      /*
        Pending styles for CSS class based example
      */
      .pending {
        color: #bdc3c7!important;
        background-color: #bdc3c7!important;
        border-color: #bdc3c7!important;
        animation-name: skeletonAnimation;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      .pending_el {
        color: #ccc !important;
        background-color: #ccc !important;
        animation-name: skeletonAnimation;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

      }
      .container2 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin: auto;
        border: 1px solid #ddd;
        border-radius: 5px;
        }
        .avatar {
            display: block;
            width: 150px;
            height: 150px;
        }

        .content {
            background-color: #f0f0f0 !important;
            padding: 10px 16px;
            color: #34495e !important;
            font-family: system-ui, sans-serif;
            width: -webkit-calc(100% - 150px);
            width: -moz-calc(100% - 150px);
            width: calc(100% - 150px);
        }
      .demo:empty {
        margin: auto;
        width: 500px;
        height: 600px; /* change height to see repeat-y behavior */
        
        background-image:
          radial-gradient( circle 50px at 50px 50px, lightgray 99%, transparent 0 ),
          linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
          linear-gradient( lightgray 20px, transparent 0 ),
          linear-gradient( lightgray 20px, transparent 0 ),
          linear-gradient( lightgray 20px, transparent 0 ),
          linear-gradient( lightgray 20px, transparent 0 );

        background-repeat: repeat-y;

        background-size:
          100px 200px, /* circle */
          50px 200px, /* highlight */
          150px 200px,
          350px 200px,
          300px 200px,
          250px 200px;

        background-position:
          0 0, /* circle */
          0 0, /* highlight */
          120px 0,
          120px 40px,
          120px 80px,
          120px 120px;

        animation: shine 1s infinite;
      }

      @keyframes shine {
        to {
          background-position:
            0 0,
            100% 0, /* move highlight to right */
            120px 0,
            120px 40px,
            120px 80px,
            120px 120px;
        }
      }

    .bg-image {
    position: relative;
    height: 0;
    overflow: hidden;
    background-color: #f0f0f0;
  /*  background-image: url();   */
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 30%
    }